import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import UrlButton from "./urlButton"
import Styles from "../styles/projectItem.module.scss"
import GithubIcon from "../images/icons/githubIcon"
import Skill from "./skill"

const ProjectItem = ({ project }) => {
  return (
    <div className={Styles.projectContainer}>
      <div className={Styles.infoContainer}>
        <div className={Styles.projectName}>{project.title}</div>
        <div className={Styles.techContainer}>
          {project.tech.map((skill, i) => (
            <Skill key={i} skill={skill} />
          ))}
        </div>
        <div className={Styles.description}>
          {documentToReactComponents(project.description.json)}
        </div>
        <div className={Styles.buttons}>
          {project.url.map((url, i) => (
            <UrlButton key={i} url={url} />
          ))}

          {project.github && (
            <a
              className={Styles.github}
              target="_blank"
              rel="noopener noreferrer"
              href={project.github}
            >
              <GithubIcon />
            </a>
          )}
        </div>
      </div>
      <img src={project.image.file.url} alt={project.image.file.fileName} />
    </div>
  )
}

export default ProjectItem
