import React, { useState } from "react"
// import Fade from "react-reveal/Fade"
import _ from "lodash"

import Styles from "../styles/interactiveTable.module.scss"
import Skill from "./skill"

const InteractiveTable = ({
  data,
  categoryNames,
  preselectedCatId,
  withSkills,
}) => {
  const [selectedCategoryName, setSelectedCategoryName] = useState(
    preselectedCatId
  )

  const onCategorySelect = name => {
    setSelectedCategoryName(name)
  }

  let content

  if (withSkills) {
    const selectedCatData = data.filter(
      entry => entry.category === selectedCategoryName
    )
    const columnIdentifiers = _.uniq(selectedCatData.map(entry => entry.type))
    const contentByColumn = columnIdentifiers.map(id =>
      selectedCatData.filter(entry => entry.type === id)
    )

    content = contentByColumn.reverse().map((column, i) => (
      <div key={i} className={Styles.column}>
        {column.reverse().map((skill, i) => (
          <Skill key={i} skill={skill} />
        ))}
      </div>
    ))
  }

  return (
    <div className={Styles.table}>
      <div className={Styles.categoryList}>
        {Object.entries(categoryNames).map(([key, catName], i) => (
          <div
            key={key}
            onClick={onCategorySelect.bind(this, key)}
            onKeyPress={onCategorySelect.bind(this, key)}
            role="button"
            tabIndex={0}
            className={`${Styles.categoryItem} ${
              key === selectedCategoryName ? Styles.active : ""
            }`}
          >
            <div className={Styles.categoryTitle}>{catName}</div>
            <div className={Styles.activeIndicator}></div>
          </div>
        ))}
      </div>
      <div className={Styles.separator}></div>
      <div className={Styles.content}>{content}</div>
    </div>
  )
}

export default InteractiveTable
