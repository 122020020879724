import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SubHeader from "../components/subHeader"
import InteractiveTable from "../components/interactiveTable"
import PageSection from "../components/pageSection"
import { skillCategoryNames } from "../constants"
import SkillLegend from "../components/skillLegend"
import ProjectItem from "../components/ProjectItem"
import Styles from "../styles/index.module.scss"
import DanielWorkingOffice from "../images/danielWorking"
import { Link } from "gatsby"
import Button from "../components/button"
import Logo from "../images/logo"

const IndexPage = () => {
  const {
    allContentfulSkill,
    contentfulAboutPage: { aboutMe },
    allContentfulProject,
  } = useStaticQuery(graphql`
    query {
      allContentfulSkill {
        edges {
          node {
            name
            type
            category
            id
          }
        }
      }
      contentfulAboutPage {
        aboutMe {
          aboutMe
          json
        }
        profileImage {
          file {
            url
            fileName
          }
        }

        educationTimeline {
          title
          details
        }
      }

      allContentfulProject {
        edges {
          node {
            order
            title
            url
            github
            tech {
              name
              type
            }
            image {
              file {
                fileName
                url
              }
            }
            description {
              description
              json
            }
          }
        }
      }
    }
  `)

  const skills = allContentfulSkill.edges.map(edge => edge.node)

  return (
    <Layout>
      <SEO title="Home" />
      <PageSection className={Styles.header}>
        <div className={Styles.leftHeader}>
          <div className={Styles.punchLine}>
            <p>
              I turn your <b>vision</b>
            </p>
            <br />
            <p>
              into <b>products</b>
            </p>
          </div>

          <div className={Styles.button}>
            <Link to="/contact">
              <Button text={"Get in touch"} />
            </Link>
          </div>
          <div className={Styles.about}>
            {documentToReactComponents(aboutMe.json)}
            <Logo className={Styles.logo} />
          </div>
        </div>
        <div className={Styles.rightHeader}>
          <DanielWorkingOffice />
        </div>
      </PageSection>
      <PageSection>
        <SubHeader emoji={"🧑🏻‍🔬"}>Projects</SubHeader>
        {allContentfulProject.edges
          .sort((a, b) => a.node.order - b.node.order)
          .map((project, i) => (
            <Fade key={i} delay={100}>
              <ProjectItem project={project.node} />
            </Fade>
          ))}
      </PageSection>
      <PageSection>
        <SubHeader emoji={"🧑🏻‍💻"}>Technologies</SubHeader>
        <div>
          <InteractiveTable
            data={skills}
            categoryNames={skillCategoryNames}
            withSkills
            preselectedCatId={"library_framework"}
          />
        </div>
        <SkillLegend />
      </PageSection>
    </Layout>
  )
}

export default IndexPage
