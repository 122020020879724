import * as React from "react"

const DanielWorking = props => (
  <svg
    viewBox="0 0 1000 1244"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M633.211 939.049c2.947-2.829 2.64-15.319-.095-19.701-10.08-9.201-17.839-13.286-38.895 2.165-1.365 1.002-27.734 24.185-29.608 28.297-4.744 10.405 3.478 15.242 15.545 12.873 16.088-6.661 35.346-15.269 53.053-23.634Z"
      fill="#7F461D"
    />
    <path
      d="M632.851 936.051c2.947-2.829 2.64-15.318-.095-19.701-10.08-9.2-17.84-13.286-38.895 2.165-1.366 1.002-27.735 24.185-29.608 28.297-4.744 10.405 3.478 15.242 15.545 12.873 16.088-6.664 35.346-15.269 53.053-23.634Z"
      fill="#84AF9A"
    />
    <path
      d="M730.716 985.609c2.947-2.829 2.64-15.318-.095-19.7-10.08-9.201-17.84-13.287-38.895 2.164-1.366 1.003-27.735 24.185-29.608 28.298-4.744 10.409 3.478 15.239 15.545 12.869 16.092-6.66 35.346-15.265 53.053-23.631Z"
      fill="#7F461D"
    />
    <path
      d="M730.353 982.604c2.947-2.829 2.64-15.318-.095-19.7-10.08-9.201-17.839-13.287-38.895 2.164-1.365 1.003-27.734 24.185-29.608 28.298-4.744 10.404 3.478 15.244 15.546 12.874 16.091-6.662 35.345-15.27 53.052-23.636Z"
      fill="#84AF9A"
    />
    <path
      d="M672.789 980.845c-.91 0 5.856-.387 9.117 5.871M679.438 974.975c-2.287-.175 7.426-.24 10.403 7.279M686.373 969.158c-2.276-.174 7.422-.254 10.402 7.28"
      stroke="#E8F2EC"
      strokeWidth={0.5}
      strokeMiterlimit={10}
    />
    <path
      d="M878.149 1131.23c3.853-1.86 4.957-12.25-3.11-18.55l-2.563 1.97c-8.602-5.55-21.716-5.55-29.377 0l-141.254 98.5c-8.506 6.17-8.476 20.25.16 26.51 8.684 6.31 22.694 6.31 34.993-3.8l141.151-104.63Z"
      fill="#C1C2C2"
    />
    <path
      d="M878.149 1127.24c3.853-1.87 2.973-11-5.673-16.59-8.602-5.55-21.716-5.55-29.377 0l-141.254 102.5c-8.506 6.17-8.476 16.25.16 22.52 8.684 6.3 22.694 6.3 34.993-3.8l141.151-104.63Z"
      fill="#D7D9DB"
    />
    <path
      d="M334.418 842.272c8.01-4.28 9.39-11.176 3.141-15.402-6.22-4.204-17.692-4.204-25.687 0l-143.59 75.524c-8.745 4.598-10.945 12.109-4.839 16.768 6.136 4.686 18.276 4.686 27.044 0l143.931-76.89Z"
      fill="#C1C2C2"
    />
    <path
      d="M334.418 839.292c8.01-4.28 9.39-11.176 3.141-15.402-6.22-4.204-17.692-4.204-25.687 0l-143.59 75.524c-8.745 4.598-10.945 12.109-4.839 16.768 6.136 4.686 18.276 4.686 27.044 0l143.931-76.89Z"
      fill="#D7D9DB"
    />
    <path
      d="M686.171 681.428c-4.661 10.533 1.274 204.676 8.385 280.146 10.566 4.466 29.479 2.043 34.22-1.481 15.298-57.483 9.572-254.299-.095-278.434-9.629-24.048-38.941-8.293-42.51-.231Z"
      fill="#5F6161"
    />
    <path
      d="M716.991 672.611c-4.661 10.533 25.414 216.58 3.558 290.524 0 0 4.6-.903 8.226-3.041 15.299-57.483 8.715-258.97-.952-283.105-9.629-24.052-7.263-12.44-10.832-4.378Z"
      fill="#505251"
    />
    <path
      d="M595.216 816.546c-4.661 10.533-4.479 35.303 2.317 100.057 10.566 4.466 27.636 1.709 32.372-1.815 11.609-54.904 13.767-74.384 6.69-101.914-6.447-25.096-37.811-4.39-41.379 3.672Z"
      fill="#5F6161"
    />
    <path
      d="M629.967 841.487c1.706 14.794.277 42.324-8.021 76.408 0 0 4.6-.904 8.226-3.041 6.424-17.908 9.708-47.182 11.157-79.997 1.142-25.886-12.371-2.131-11.362 6.63Z"
      fill="#505251"
    />
    <path
      d="M803.547 1163.96c.959 14.8-18.416 27.15-34.879 13.54-.08-48.85.015-424.58.015-424.58l34.868-21.876c-.004.004-2.318 324.716-.004 432.916ZM262.139 868.737s-5.495 18.561-30.647 6.843c-.163-76.769.087-476.837.087-476.837l30.56-16.176v486.17Z"
      fill="#C1C2C2"
    />
    <path
      d="M987.212 633.022c1.093-.638 4.328-3.266 4.373-6.596.008-.593.228-9.63 0-10.26-.747-2.093-3.25 4.074-7.987 1.921L394.595 350.482c-6.057-2.753-16.713-2.772-23.855-.041L12.05 487.488c-4.244 1.621-7.6-5.017-8.727-2.871-.436.828-.057 10.412.015 11.221.137 1.485 1.15 2.897 3.114 4.067l581.38 346.508c9.182 5.471 24.257 5.509 33.56.079l365.82-213.47Z"
      fill="#C1C2C2"
    />
    <path
      d="M983.89 622.886c7.088-4.135 5.435-10.822-3.614-14.935L391.273 340.347c-6.057-2.753-16.713-2.772-23.855-.042L8.728 477.352c-8.878 3.391-11.416 8.954-5.602 12.421l581.38 346.508c9.182 5.471 24.257 5.509 33.56.079L983.89 622.886Z"
      fill="#D7D9DB"
    />
    <path
      d="M454.919 636.333v-1.903l-78.383-40.889-64.396 27.971v1.898l78.106 44.573 64.673-31.65Z"
      fill="#A09072"
    />
    <path
      d="m454.919 634.429-78.383-42.792-64.396 29.874 78.106 44.572 64.673-31.654Z"
      fill="#CEBB97"
    />
    <path
      d="m523.563 433.923 210.47 102.868v1.424l-68.791 35.538-210.323-109.847 68.644-29.983Z"
      fill="#A09F9F"
    />
    <path
      d="m523.563 432.493 210.47 104.296-69.03 34.809L454.919 462.48l68.644-29.987Z"
      fill="#E9E9EC"
    />
    <path
      d="M614.821 341.679c-3.758 17.05 3.607 32.938 1.396 47.504-10.202 21.91-9.371 31.734-35.35 56.913 0 0 6.523 8.863 15.261 11.654 36.101-36.83 55.795-60.604 55.897-111.503l-37.204-4.568Z"
      fill="#E0A186"
    />
    <path
      d="M661.062 346.688c21.454-3.46-2.916-48.267-7.964-82.017-.971-6.501-37.011-4.158-37.011 24.055 0 22.161-1.187 37.229-4.573 52.596.003 0 21.943 9.459 49.548 5.366Z"
      fill="#F2E2D6"
    />
    <path
      d="m595.218 627.593-4.271 1.268c-134.124-45.252-132.994-45.738-208.293-79.181-62.583-27.792-120.517-96.032-120.517-96.032l-4.27-217.519 4.27-1.268s70.502 54.597 151.831 89.63c81.61 35.155 181.25 57.669 181.25 57.669v245.433Z"
      fill="#CAB48B"
    />
    <path
      d="M590.947 628.863s-143.863-47.401-212.563-77.912c-62.584-27.793-120.517-96.033-120.517-96.033V236.131s70.502 54.597 151.831 89.631c81.61 35.155 181.249 57.669 181.249 57.669v245.432Z"
      fill="#DFD1B8"
    />
    <path
      d="m393.527 635.43-15.569-8.882V410.879l15.569 7.614V635.43Z"
      fill="#A09072"
    />
    <path
      d="m391.31 635.43-15.564-8.882V410.879l15.564 7.614V635.43Z"
      fill="#CAB48B"
    />
    <path
      d="M796.857 587.113v-1.902l-58.461-27.029-45.873 21.078v1.903l58.476 29.854 45.858-23.904Z"
      fill="#9F9E9E"
    />
    <path
      d="m796.857 585.155-58.461-28.927-45.873 22.977 58.476 29.858 45.858-23.908Z"
      fill="#E9E9EC"
    />
    <path
      d="M308.358 516.894v-3.801L179.6 446.101 67.738 486.174v3.801l127.093 75.626 113.527-48.707Z"
      fill="#898888"
    />
    <path
      d="M308.358 513.09 179.6 442.298 67.738 486.171l127.093 75.627 113.527-48.708Z"
      fill="#A09F9F"
    />
    <path
      d="M187.305 500.24c-4.831 1.97-11.54 1.97-14.969 0-3.417-1.964-2.26-5.138 2.564-7.086 4.809-1.945 11.469-1.945 14.893 0 3.433 1.952 2.329 5.122-2.488 7.086Z"
      fill="#C4C4C3"
    />
    <path
      d="M625.294 447.355c33.78-86.32-8.81-169.326 13.611-184.06 11.65-7.697 34.583-4.226 44.003-1.219 9.546 3.05-5.26 10.378 15.102 10.154 11.066-.121 20.377-10.742 37.928-6.546 15.936 3.812 30.025 5.305 42.157 21.678 20.715 27.964-6.27 133.651-13.707 222.648-30.988-12.748-101.253-45.488-139.094-62.655Z"
      fill="#F6EADE"
    />
    <path
      d="M797.93 380.511c.489 13.75.284 43.399-2.488 56.272-36.609 42.89-51.175 43.133-95.248 76.754-16.732-6.634-13.216-9.698-13.216-9.698s52.518-54.555 64.957-75.547c.178-16.013-4.259-25.635-7.403-50.078l53.398 2.297Z"
      fill="#E0A186"
    />
    <path
      d="M740.998 377.982c-3.296-29.694-11.393-63.452-3.85-79.032 6.778-13.997 19.839-25.802 33.844-16.085 22.201 15.401 28.77 65.54 29.308 98.96-21.419 9.896-59.302-3.843-59.302-3.843Z"
      fill="#F2E2D6"
    />
    <path
      d="M666.818 186.629s-11.202-12.247-10.891-23.832c.311-11.585 8.908-20.52 16.508-22.176 7.6-1.656 22.933 4.302 25.182 13.902 2.249 9.599 1.259-18.865 21.424-8.605 20.164 10.26 14.544 20.521 21.818 30.12 7.274 9.6 9.587 5.628 16.857 5.628s3.254 30.119-11.76 32.105c-15.015 1.986-11.871 2.978-16.668 14.233-4.798 11.255-10.907-10.921-11.571-15.558-.66-4.632-14.199-9.93-14.199-9.93l-30.097-7.283-6.603-8.604Z"
      fill="#724644"
    />
    <path
      d="M662.606 219.728c-2.499 7.237-3.721 25.483.246 30.78 3.967 5.297 12.561 13.902 26.445 10.26 13.884-3.641 21.488-6.618 26.775-12.576 5.287-5.958 12.891-16.218 20.824-18.535 7.934-2.316 10.907-8.604 7.934-12.246-2.973-3.641-10.577-5.297-15.204 3.972-4.627 9.269-7.604 6.288-8.594-1.655-.989-7.944 5.291-5.628.66-12.577-4.626-6.949-2.643-7.944-2.313-10.591.33-2.646-9.587 3.312-29.088 0-19.505-3.311-23.472-9.929-23.472-9.929s-1.896 26.379-4.213 33.097Z"
      fill="#E0A186"
    />
    <path
      d="m726.57 236.524-6.201 29.87s4.957 7.53 3.573 12.06c-1.385 4.522-2.606 14.923-29.354 10.776-13.884-2.153-15.864-15.223-15.868-18.534-.004-4.469 4.957-7.427 4.957-7.427l-.66-9.121 43.553-17.624Z"
      fill="#E0A186"
    />
    <path
      d="M402.621 382.755c0 8.187-5.367 12.136-11.958 8.84-6.558-3.281-11.852-12.508-11.852-20.62 0-8.111 5.294-12.102 11.852-8.893 6.591 3.224 11.958 12.486 11.958 20.673Z"
      fill="#EEE7D7"
    />
    <path
      d="m700.192 513.536-11.469 14.529-22.853 13.146-19.649.71-4.38-6.755 13.369-12.285-5.61 2.355-2.848-.357-1.483-1.603 9.702-5.263 22.208-7.606 9.489-6.174 13.524 9.303Z"
      fill="#E0A186"
    />
    <path
      d="M685.192 243.402s-6.231 4.07-10.983 2.145c-4.733-1.918-6.186-9.049-6.186-9.049l17.169 6.904Z"
      fill="#E5E4E3"
    />
    <path
      d="M693.47 215.957c0 1.484-.849 2.362-1.896 1.959s-1.897-1.933-1.897-3.414.85-2.358 1.897-1.959c1.047.402 1.896 1.929 1.896 3.414ZM676.709 209.625c0 1.485-.85 2.362-1.896 1.96-1.047-.403-1.897-1.933-1.897-3.414s.85-2.358 1.897-1.96c1.043.399 1.896 1.929 1.896 3.414Z"
      fill="#383838"
    />
    <path
      d="M682.642 211.377s-8.48 12.546-8.628 14.54c-.19 2.559 8.628 5.533 8.628 5.533"
      fill="#D08C71"
    />
    <path
      d="M575.286 934.287c-.91 0 5.855-.387 9.117 5.871M581.935 928.417c-2.287-.175 7.426-.239 10.403 7.28"
      stroke="#E8F2EC"
      strokeWidth={0.5}
      strokeMiterlimit={10}
    />
    <path
      d="M572.667 0 263.152 235.582s70.173 55.136 152.878 90.223c82.704 35.087 180.446 57.642 180.446 57.642v245.607l403.497-233.076L572.667 0Z"
      fill="url(#a)"
    />
    <path
      d="M588.87 922.603c-2.275-.174 7.422-.254 10.403 7.28"
      stroke="#E8F2EC"
      strokeWidth={0.5}
      strokeMiterlimit={10}
    />
    <path
      d="M607.967 784.928c30.599 0 55.404-11.486 55.404-25.655 0-14.168-24.805-25.654-55.404-25.654-30.599 0-55.404 11.486-55.404 25.654 0 14.169 24.805 25.655 55.404 25.655Z"
      fill="#593A3C"
    />
    <path
      d="M607.967 782.075c30.599 0 55.404-11.486 55.404-25.655 0-14.168-24.805-25.654-55.404-25.654-30.599 0-55.404 11.486-55.404 25.654 0 14.169 24.805 25.655 55.404 25.655Z"
      fill="#7D5050"
    />
    <path d="M612.184 739.791h-8.066v16.629h8.066v-16.629Z" fill="#5E4041" />
    <path
      d="m612.178 742.029-7.285 1.899-28.455-110 8.01 1.379 27.73 106.722Z"
      fill="#5E4041"
    />
    <path
      d="m584.315 635.825-7.877-1.899 62.42-85.525 11.162-3.391v6.512l-5.082 1.329-60.623 82.974Z"
      fill="#5E4041"
    />
    <path
      d="M687.679 618.122c14.165-7.451 14.719-19.495 1.293-26.9-13.395-7.39-35.687-7.39-49.848 0-7.096 3.702-10.82 8.567-11.093 13.435-.273 4.868 2.898 9.74 9.595 13.465 13.422 7.465 35.854 7.465 50.053 0Z"
      fill="#7D5050"
    />
    <path
      d="M628.033 604.657s-3.091-35.011 21.26-47.39c8.309-.262 28.114-.528 28.114-.528 24.696 9.387 21.275 47.96 21.275 47.96-33.533.004-70.649-.042-70.649-.042Z"
      fill="#7D5050"
    />
    <path
      d="M677.42 540.846c.098-1.94-1.18-3.877-3.854-5.354-5.321-2.936-14.176-2.936-19.801 0-2.818 1.469-4.297 3.402-4.407 5.335-.11 1.933-.068 16.439-.068 16.439l.114-.004c.133 1.75 1.335 3.467 3.747 4.807 5.332 2.966 14.245 2.966 19.884 0 2.803-1.473 4.263-3.402 4.362-5.331h.007c0 .004-.083-13.963.016-15.892Z"
      fill="#684345"
    />
    <path
      d="M673.029 546.177c5.629-2.958 5.849-7.742.512-10.685-5.32-2.936-14.176-2.936-19.8 0-5.64 2.943-5.917 7.727-.596 10.685 5.332 2.966 14.245 2.966 19.884 0Z"
      fill="#7D5050"
    />
    <path
      d="M386.82 553.991c0 3.444-1.718 5.191-3.834 3.896-2.112-1.291-3.823-5.119-3.823-8.556 0-3.436 1.711-5.183 3.823-3.903 2.116 1.283 3.834 5.119 3.834 8.563Z"
      fill="#A09072"
    />
    <path
      d="M387.886 553.991c0 3.444-1.718 5.191-3.835 3.896-2.112-1.291-3.822-5.119-3.822-8.556 0-3.436 1.71-5.183 3.822-3.903 2.117 1.283 3.835 5.119 3.835 8.563Z"
      fill="#D7D9DB"
    />
    <path
      d="m387.889 555.08-7.388-3.14-.265-2.605c0-3.437 1.748-5.248 3.823-3.904 2.431 1.58 4.153 5.499 3.83 9.649Z"
      fill="#DFD1B8"
    />
    <path
      d="M206.724 563.577s27.222 18.409 63.285 23.478c36.063 5.07 71.173 23.122 77.18 26.604 6.008 3.482 52.632 34.942 35.805-27.978-2.275-6.228-8.245-24.956 1.062-40.251"
      stroke="#BCBEC0"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <path
      d="M208.686 564.797c-1.449.627-3.421.627-4.407 0l-3.478-2.21c-.978-.623.531-.911 1.976-1.534 1.445-.623 2.276-1.34 3.258-.721l3.481 2.202c.986.623.615 1.637-.83 2.263Z"
      fill="#BCBEC0"
    />
    <defs>
      <linearGradient
        id="a"
        x1={301.998}
        y1={586.449}
        x2={844.588}
        y2={246.859}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.397} stopColor="#fff" stopOpacity={0.44} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default DanielWorking
